var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      attrs: {
        "default-active": _vm.activeMenu,
        mode: "horizontal",
        "unique-opened": "",
        router: ""
      }
    },
    [
      _vm._l(_vm.topRoute, function(route, key, index) {
        return [
          !(
            route.children &&
            route.children.length &&
            !_vm.hasChildren(route.children)
          )
            ? _c("el-menu-item", { key: index, attrs: { index: route.path } }, [
                _vm._v(_vm._s(key))
              ])
            : _c(
                "el-submenu",
                { key: index, attrs: { index: key } },
                [
                  _c("template", { slot: "title" }, [_vm._v(_vm._s(key))]),
                  _vm._v(" "),
                  _vm._l(route.children, function(item, routeIndex) {
                    return !(
                      item.meta.topMenuHidden &&
                      item.meta.topMenuHidden === true
                    )
                      ? _c(
                          "el-menu-item",
                          { key: routeIndex, attrs: { index: item.path } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.name) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  })
                ],
                2
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }