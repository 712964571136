import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";

/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios';
import store from '@/store/';
import router from '@/router/router';
import { serialize } from '@/util/util';
import { getToken } from '@/util/auth';
import { Message } from 'element-ui';
import website from '@/config/website';
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css'; // progress bar style

import { Base64 } from 'js-base64'; //设置默认超时等待时间

axios.defaults.timeout = 60000; //返回其他状态吗

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
}; // 基础API地址
// axios.defaults.base_url = process.env.VUE_APP_BASE_URL;
// console.log(process.env)
// if(process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// }
//跨域请求，允许保存cookie


axios.defaults.withCredentials = true; // NProgress Configuration

NProgress.configure({
  showSpinner: false
}); //HTTPrequest拦截

axios.interceptors.request.use(function (config) {
  NProgress.start(); // start progress bar

  var meta = config.meta || {};
  var isToken = meta.isToken === false;
  config.headers['Authorization'] = "Basic ".concat(Base64.encode("".concat(website.clientId, ":").concat(website.clientSecret)));

  if (getToken() && !isToken) {
    config.headers['Micro-Auth'] = 'bearer ' + getToken(); // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
  } //headers中配置serialize为true开启序列化


  if (config.method === 'post' && meta.isSerialize === true) {
    config.data = serialize(config.data);
  }

  return config;
}, function (error) {
  return Promise.reject(error);
}); //HTTPresponse拦截

axios.interceptors.response.use(function (res) {
  NProgress.done();
  var promptText = {
    404: '系统升级中！请稍等片刻刷新重试',
    503: '系统升级中！请稍等片刻刷新重试'
  };
  var status = res.data.code || 200;
  var statusWhiteList = website.statusWhiteList || [];
  var message = res.data.message || res.data.msg || '未知错误'; //如果在白名单里则自行catch逻辑处理

  if (statusWhiteList.includes(status)) return Promise.reject(res); //如果是401则跳转到登录页面

  if (status === 401 || status === 300) store.dispatch('FedLogOut').then(function () {
    return router.push({
      path: '/login'
    });
  }); // 如果请求为非200否者默认统一处理

  if (status !== 200) {
    Message({
      message: promptText[status] || message,
      type: 'error'
    });

    try {
      Promise.reject(new Error(message));
    } catch (e) {
      console.info(e);
    }

    return res;
  }

  return res;
}, function (error) {
  NProgress.done(); // 接口超时提示

  if (error.message.includes('timeout')) {
    Message({
      message: '数据过多，系统正在处理中，请稍后查看......',
      type: 'warning'
    });
  }

  try {
    Promise.reject(new Error(error));
  } catch (e) {
    console.info(e);
  }

  return error;
});
export default axios;