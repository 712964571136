import Layout from '@/page/index';
export default {
  path: '/resourceManage',
  code: "ZYGLXT",
  redirect: '/resourceManage/wrapper',
  component: Layout,
  meta: {
    title: '基础管理',
    icon: 'jhicon icon-fangwufangchan',
    activeMenu: '/resourceManage/wrapper'
  },
  children: [{
    path: 'wrapper',
    name: '基础管理',
    hidden: true,
    code: "ZYGLXT",
    component: function component() {
      return import('@/views/resourceManage/wrapper');
    },
    meta: {
      activeMenu: '/resourceManage/wrapper'
    },
    children: [{
      path: 'project',
      name: '项目',
      code: "ZYGLXT-02-0000",
      meta: {
        activeMenu: '/resourceManage/wrapper'
      },
      component: function component() {
        return import('@/views/resourceManage/project/index');
      }
    }, {
      path: 'building',
      name: '楼宇',
      code: "ZYGLXT-03-0000",
      meta: {
        activeMenu: '/resourceManage/wrapper'
      },
      component: function component() {
        return import('@/views/resourceManage/building/index');
      }
    }, {
      path: 'group',
      code: "ZYGLXT-01-0000",
      name: '集团 业态 省 公司',
      meta: {
        activeMenu: '/resourceManage/wrapper'
      },
      component: function component() {
        return import('@/views/resourceManage/group/index');
      }
    }, {
      path: 'room',
      name: '房间/车位/商业/会所等',
      code: "ZYGLXT-06-0000",
      meta: {
        activeMenu: '/resourceManage/wrapper'
      },
      component: function component() {
        return import('@/views/resourceManage/room/index');
      }
    }, {
      path: 'storey',
      name: '楼层',
      code: "ZYGLXT-05-0000",
      meta: {
        activeMenu: '/resourceManage/wrapper'
      },
      component: function component() {
        return import('@/views/resourceManage/storey/index');
      }
    }, {
      path: 'unit',
      name: '单元',
      code: "ZYGLXT-04-0000",
      meta: {
        activeMenu: '/resourceManage/wrapper'
      },
      component: function component() {
        return import('@/views/resourceManage/unit/index');
      }
    }]
  }]
};