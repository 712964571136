import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import request from '@/router/axios';
export var loginByUsername = function loginByUsername(tenantCode, account, password, type) {
  return request({
    url: '/api/micro-auth/token',
    method: 'get',
    params: {
      tenantCode: tenantCode,
      account: account,
      password: password,
      type: type
    }
  });
}; // 修改密码

export var changePassword = function changePassword(data) {
  return request({
    url: '/api/micro-user/updatePassword',
    method: 'post',
    params: _objectSpread({}, data)
  });
}; // 查询当前系统版本号 0 - PC, 1 - POS

export var querySystemVersion = function querySystemVersion() {
  return request({
    url: '/api/micro-jumbo-charging/download/version/querySystemVersion',
    method: 'GET',
    params: {
      systemTag: 0
    }
  });
}; // 根据token登录

export var loginByToken = function loginByToken(tenantCode, token) {
  return request({
    url: '/api/micro-jumbo-charging/download/sso/auth/token',
    method: 'get',
    params: {
      tenantCode: tenantCode,
      token: token
    }
  });
}; // 记录登录详情

export var recordLoginDetails = function recordLoginDetails() {
  return request({
    url: '/api/micro-jumbo-resource/login/recordLoginDetails',
    method: 'POST'
  });
}; // 记住密码

export var rememberPassword = function rememberPassword(params) {
  return request({
    url: '/api/micro-jumbo-resource/download/login/rememberPassword',
    method: "POST",
    params: _objectSpread({}, params)
  });
}; // 取消记住密码

export var unRememberPassword = function unRememberPassword(params) {
  return request({
    url: '/api/micro-jumbo-resource/download/login/unRememberPassword',
    method: 'POST',
    params: _objectSpread({}, params)
  });
}; // 查询是否记住密码

export var queryRememberPassword = function queryRememberPassword(params) {
  return request({
    url: '/api/micro-jumbo-resource/download/login/queryRememberPwd',
    method: 'GET',
    params: _objectSpread({}, params)
  });
};
export var getButtons = function getButtons() {
  return request({
    url: '/api/api/micro-system/menu/buttons',
    method: 'get'
  });
};
export var getUserInfo = function getUserInfo() {
  return request({
    url: '/api/user/getUserInfo',
    method: 'get'
  });
};
export var refeshToken = function refeshToken() {
  return request({
    url: '/api/user/refesh',
    method: 'post'
  });
};
export var getMenu = function getMenu() {
  return request({
    url: '/api/micro-system/menu/routes',
    method: 'get'
  });
};
export var getTopMenu = function getTopMenu() {
  return request({
    url: '/api/user/getTopMenu',
    method: 'get'
  });
};
export var sendLogs = function sendLogs(list) {
  return request({
    url: '/api/user/logout',
    method: 'post',
    data: list
  });
};
export var logout = function logout() {
  return request({
    url: '/api/micro-jumbo-charging/logout',
    method: 'post'
  });
};
export var getCampusList = function getCampusList() {
  return request({
    url: '/api/micro-campus/campusInfo/list',
    method: 'get'
  });
};
export var getRoutes = function getRoutes() {
  return request({
    url: '/api/micro-system/menu/routes',
    method: 'get'
  });
};
export var getBtnRules = function getBtnRules() {
  return request({
    url: '/api/micro-system/menu/buttons',
    method: 'get'
  });
};
export var jumpIssue = function jumpIssue() {
  return request({
    url: '/api/micro-jumbo-charging/issue/getIssueUrl',
    method: 'get'
  });
};