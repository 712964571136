import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.to-string";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters, mapState } from "vuex";
import { fullscreenToggel, listenfullscreen } from "@/util/util";
import { changePassword, jumpIssue //跳转至工单系统
} from '@/api/user';
import { queryUnreadInfo } from '@/api/OA/OAmessage';
import checkProject from "./checkProject";
import kyMenu from "./ky-menu";
import logo from "../sidebar/logo";
import kyTags from "../TagsView";
import '@/util/dialogDrag';
import { getAccountDataPermissions } from "@/api/resourceManage/common";
export default {
  components: {
    checkProject: checkProject,
    kyMenu: kyMenu,
    kyTags: kyTags,
    logo: logo
  },
  name: "top",
  data: function data() {
    var _this = this;

    var validatePsw = function validatePsw(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入确认密码'));
      }

      if (value != _this.passwordForm.password) {
        return callback(new Error('请核对密码是否一致'));
      }

      callback();
    };

    return {
      loading: false,
      billingSystemActiveMenu: null,
      changePswVisible: false,
      passwordForm: {
        password: null,
        confirmPassword: null
      },
      rules: {
        password: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }],
        confirmPassword: [{
          required: true,
          validator: validatePsw,
          trigger: 'blur'
        }]
      },
      //消息红点展示
      isDot: false,
      //消息数量展示
      totalMessage: 0,
      treeData: []
    };
  },
  mounted: function mounted() {
    listenfullscreen(this.setScreen);
    this.loadTreeData();
  },
  computed: _objectSpread({}, mapState({
    showDebug: function showDebug(state) {
      return state.common.showDebug;
    },
    showTheme: function showTheme(state) {
      return state.common.showTheme;
    },
    showLock: function showLock(state) {
      return state.common.showLock;
    },
    showFullScren: function showFullScren(state) {
      return state.common.showFullScren;
    },
    showCollapse: function showCollapse(state) {
      return state.common.showCollapse;
    },
    showSearch: function showSearch(state) {
      return state.common.showSearch;
    },
    showMenu: function showMenu(state) {
      return state.common.showMenu;
    },
    showColor: function showColor(state) {
      return state.common.showColor;
    },
    showCampus: function showCampus(state) {
      return state.common.showCampus;
    }
  }), {}, mapGetters(["userInfo", "isFullScren", "tagWel", "tagList", "isCollapse", "tag", "logsLen", "logsFlag", "roles", "activeProject"]), {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    }
  }),
  methods: {
    // async initData() {
    //   await Promise.all([
    //     this.loadTreeData(),
    //     this.loadOAmessage()
    //   ])
    // },
    loadTreeData: function loadTreeData() {
      var _this2 = this;

      getAccountDataPermissions().then(function (res) {
        var data = res.data;

        if (data.code === 0) {
          if (data.content.length) {
            // this.menuData = this.toTree(data.content);
            _this2.treeData = data.content;

            _this2.loadOAmessage();
          }
        }
      });
    },
    //获取消息中心数据
    loadOAmessage: function loadOAmessage() {
      var _this3 = this;

      queryUnreadInfo({
        communityUuid: this.activeProject.org_uuid,
        username: this.$store.state.user.userInfo.account,
        messageDimension: [0, 1, 2].toString()
      }).then(function (res) {
        if (res.data.code === 0) {
          //个人、项目、系统 消息数量累加
          var totalMessage = res.data.content.reduce(function (prev, next) {
            if (next) {
              return prev + next.number;
            } else {
              return prev;
            } // return prev + next.number

          }, 0); //红点展示判断

          _this3.isDot = totalMessage > 0 ? true : false;
          _this3.totalMessage = totalMessage;
        }
      });
    },
    handleScreen: function handleScreen() {
      fullscreenToggel();
    },
    setCollapse: function setCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    setScreen: function setScreen() {
      this.$store.commit("SET_FULLSCREN");
    },
    logout: function logout() {
      var _this4 = this;

      this.$confirm(this.$t("logoutTip"), this.$t("tip"), {
        confirmButtonText: this.$t("submitText"),
        cancelButtonText: this.$t("cancelText"),
        type: "warning"
      }).then(function () {
        _this4.$store.dispatch("LogOut").then(function () {
          _this4.$router.push({
            path: "/login"
          });
        });
      });
    },
    jumpUrl: function jumpUrl(url) {
      this.$router.push({
        path: url
      });
    },
    editpsw: function editpsw() {
      this.changePswVisible = true;
    },
    changePswSubmit: function changePswSubmit() {
      var _this5 = this;

      this.$refs.pwsForm.validate(function (valid) {
        if (valid) {
          _this5.loading = true;
          changePassword({
            password: _this5.passwordForm.password
          }).then(function (res) {
            if (res.data.code === 200) {
              _this5.$message({
                type: "success",
                message: res.data.msg
              });

              _this5.$store.dispatch("LogOut").then(function () {
                _this5.$router.push({
                  path: "/login"
                });
              });
            }
          }).finally(function () {
            _this5.loading = false;
          });
        }
      });
    },
    handleClose: function handleClose() {
      if (this.loading) {
        return;
      }

      this.changePswVisible = false;
      this.passwordForm.password = null;
      this.passwordForm.confirmPassword = null;
      this.$refs.pwsForm.clearValidate();
    },
    coding: function coding() {
      this.$message({
        message: '正在开发中，敬请期待~'
      });
    },
    orderBack: function orderBack() {
      var _this6 = this;

      jumpIssue().then(function (res) {
        if (res.data.code === 0) {
          window.open(res.data.content, "_blank");
        } else {
          _this6.$message.error('跳转工单系统失败，请联系管理员！');
        }
      });
    },
    linkmessageSystem: function linkmessageSystem() {
      this.isDot = false;
      var routeInfo = this.$router.resolve({
        path: "/messageSystem/index" // query: params

      });
      window.open(routeInfo.href, "_blank");
    }
  }
};