/**
 * 全站路由配置
 *
 * meta参数说明
 * keepAlive是否缓冲页面
 * isTab是否加入到tag导航
 * isAuth是否需要授权
 */
// import VueRouter from 'vue-router';
// import IndexRouter from './modules/index'
// import AvueRouter from './avue-router';
// import Vue from 'vue';
// import i18n from '@/lang' // Internationalization
// import Store from '../store/';
// let Router = new VueRouter({
//     scrollBehavior(to, from, savedPosition) {
//         if (savedPosition) {
//             return savedPosition
//         } else {
//             if (from.meta.keepAlive) {
//                 from.meta.savedPosition = document.body.scrollTop;
//             }
//             return {
//                 x: 0,
//                 y: to.meta.savedPosition || 0
//             }
//         }
//     },
//     routes: IndexRouter
// });
// AvueRouter.install(Vue, Router, Store, i18n);
// Router.$avueRouter.formatRoutes(Store.state.user.menu, true);
// // Router.addRoutes([...ViewsRouter]);
// export default Router;
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import { constantRoutes } from "./modules/index";

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;