import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import _toConsumableArray from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ScrollPane from "./ScrollPane";
import path from 'path';
export default {
  components: {
    ScrollPane: ScrollPane
  },
  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes: function routes() {
      return this.$router.options.routes;
    }
  },
  data: function data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      affixTags: [],
      selectedTag: []
    };
  },
  watch: {
    $route: function $route() {
      this.addTags();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted: function mounted() {
    this.initTags();
    this.addTags();
  },
  methods: {
    isActive: function isActive(route) {
      if (this.$route.path.includes('/resourceManage/wrapper') && route.path.includes('/resourceManage/wrapper')) {
        return true;
      } else {
        return route.path === this.$route.path;
      }
    },
    isAffix: function isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    filterAffixTags: function filterAffixTags(routes) {
      var _this = this;

      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var tags = [];
      routes.forEach(function (route) {
        if (route.meta && route.meta.affix) {
          var tagPath = path.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: _objectSpread({}, route.meta)
          });
        }

        if (route.children && route.children.length) {
          var tempTags = _this.filterAffixTags(route.children, route.path);

          if (tempTags.length >= 1) {
            tags = [].concat(_toConsumableArray(tags), _toConsumableArray(tempTags));
          }
        }
      });
      return tags;
    },
    initTags: function initTags() {
      var affixTags = this.affixTags = this.filterAffixTags(this.routes);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = affixTags[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var tag = _step.value;

          // Must have tag name
          if (tag.name) {
            this.$store.dispatch('tagsView/addView', tag);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    addTags: function addTags() {
      var name = this.$route.name;

      if (name) {
        if (this.$route.path.includes('/resourceManage/wrapper') && this.$route.path.replace('/resourceManage/wrapper', '').length) {
          this.$store.dispatch('tagsView/addView', {
            name: "基础管理",
            path: "/resourceManage/wrapper",
            query: {},
            fullPath: "/resourceManage/wrapper",
            meta: {}
          });
          return;
        } // if(this.$route.path.includes('/reportForm') 
        // && this.$route.path.replace('/reportForm', '').length) {
        //     this.$store.dispatch('tagsView/addView', {
        //         name: this.$route.name,
        //         path: this.$route.path,
        //         query: {},
        //         fullPath: this.$route.path,
        //         meta: {},
        //     })
        //     return
        // }


        this.$store.dispatch('tagsView/addView', this.$route);
      }

      return false;
    },
    closeAllTags: function closeAllTags(view) {
      var _this2 = this;

      this.$store.dispatch('tagsView/delAllViews').then(function (_ref) {
        var visitedViews = _ref.visitedViews;

        if (_this2.affixTags.some(function (tag) {
          return tag.path === view.path;
        })) {
          return;
        }

        _this2.toLastView(visitedViews, view);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var latestView = visitedViews.slice(-1)[0];

      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({
            path: '/redirect' + view.fullPath
          });
        } else {
          this.$router.push('/');
        }
      }
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this3 = this;

      // let keepAlive = this.$store.state.common.keepAlivePage;
      // if(keepAlive.includes('reportForm')) {
      //     keepAlive.splice(keepAlive.indexOf('reportForm'), 1)
      //     this.$store.state.common.keepAlivePage = keepAlive
      // }
      this.$store.dispatch('tagsView/delView', view).then(function (_ref2) {
        var visitedViews = _ref2.visitedViews;

        if (_this3.isActive(view)) {
          _this3.toLastView(visitedViews, view);
        }
      });
    },
    openMenu: function openMenu(tag, e) {
      var menuMinWidth = 105;
      var offsetLeft = this.$el.getBoundingClientRect().left; // container margin left

      var offsetWidth = this.$el.offsetWidth; // container width

      var maxLeft = offsetWidth - menuMinWidth; // left boundary

      var left = e.clientX - offsetLeft + 15; // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }

      this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    }
  }
};