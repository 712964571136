import Layout from '@/page/index';
export default {
  path: 'messageSystem',
  name: '消息中心',
  // code: "SFXT-18-0000",
  hidden: true,
  component: Layout,
  // component: () => import('@/views/messageSystem/index'),
  redirect: '/messageSystem/index/',
  children: [{
    path: '/messageSystem/index',
    name: '个人消息',
    hidden: true,
    component: function component() {
      return import('@/views/messageSystem/index');
    },
    meta: {
      topMenuHidden: true
    }
  } // {
  //     path: '/messageSystem/projectMessage',
  //     name: '项目消息',
  //     hidden: true,
  //     component: () => import('@/views/messageSystem/projectMessage'),
  //     meta: {
  //         topMenuHidden: true
  //     }
  // }, {
  //     path: '/messageSystem/systemNotic',
  //     name: '系统通知',
  //     hidden: true,
  //     component: () => import('@/views/messageSystem/systemNotic'),
  //     meta: {
  //         topMenuHidden: true
  //     }
  // }
  ]
}; // export default [
// {
//     path: 'reportForm',
//     name: '消息中心',
//     // code: "SFXT-18-0000",
//     hidden: true,
//     component: () => import('@/views/billingSystem/reportForm/index'),
//     redirect: '/reportForm/roomInfo',
//     children: [{
//         path: '/reportForm/roomInfo',
//         name: '房间资料一览表',
//         hidden: true,
//         component: () => import('@/views/billingSystem/reportForm/roomInfo'),
//         meta: {
//             topMenuHidden: true
//         }
//     }, {
//         path: '/reportForm/customerInfo',
//         name: '客户资料一览表',
//         hidden: true,
//         component: () => import('@/views/billingSystem/reportForm/customerInfo'),
//         meta: {
//             topMenuHidden: true
//         }
//     }]
// },
// ]