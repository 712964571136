import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang

import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang

import enLocale from "./en";
import zhLocale from "./zh";
import { getStore } from '@/util/store';
Vue.use(VueI18n); // const Avue = window.AVUE;

var messages = {
  en: _objectSpread({}, enLocale, {}, elementEnLocale),
  zh: _objectSpread({}, zhLocale, {}, elementZhLocale)
};
var i18n = new VueI18n({
  locale: getStore({
    name: 'language'
  }) || 'zh',
  messages: messages
});
export default i18n;