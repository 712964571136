export default [{
  path: 'reportForm',
  name: '查看报表',
  code: "SFXT-18-0000",
  hidden: true,
  meta: {
    topGroup: '报表管理'
  },
  component: function component() {
    return import('@/views/billingSystem/reportForm/index');
  },
  redirect: '/reportForm/roomInfo',
  children: [{
    path: '/reportForm/roomInfo',
    name: '房间资料一览表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/roomInfo');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/customerInfo',
    name: '客户资料一览表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/customerInfo');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/receivableDetails',
    name: '应收费用明细表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/receivableDetails');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/adjustmentAR',
    name: '应收调整汇总表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/adjustmentAR');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/arrearsDetails',
    name: '欠费明细表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/arrearsDetails');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/receivablesDetails',
    name: '收款明细表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/receivablesDetails');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/receiptDetails',
    name: '收据明细表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/receiptDetails');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/waitingTermination',
    name: '结转明细表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/waitingTermination');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/monthEndCarryForward',
    name: '月末结转表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/monthEndCarryForward');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/refundDetail',
    name: '退款明细表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/refundDetail');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/clearRate',
    name: '清欠率统计表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/clearRate');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/chargeStatistics',
    name: '收费统计表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/chargeStatistics');
    },
    meta: {
      topMenuHidden: true
    }
  }, {
    path: '/reportForm/projectBaseData',
    name: '项目基础资料查询表',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/reportForm/projectBaseData');
    },
    meta: {
      topMenuHidden: true
    }
  }]
}, {
  path: 'exportList',
  name: '导出列表',
  // code: "SFXT-18-0000",
  hidden: true,
  meta: {
    topGroup: '报表管理'
  },
  component: function component() {
    return import('@/views/billingSystem/reportForm/exportList');
  }
}];