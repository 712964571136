import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import request from '@/router/axios'; // import { getToken } from '@/util/auth'
// 获取集团、业态、省、公司菜单

export var getAccountDataPermissions = function getAccountDataPermissions() {
  return request({
    url: '/api/micro-jumbo-charging/permissions/getAccountDataPermissions',
    method: 'get' // params: {
    //     username:'bear',
    //     userToken: getToken()
    // }

  });
}; // 获取楼宇维度菜单

export var getBuildingBySuper = function getBuildingBySuper(params) {
  return request({
    url: '/api/micro-jumbo-resource/building/getBuildingBySuper',
    method: 'get',
    params: _objectSpread({}, params)
  });
}; // 获取楼宇维度菜单

export var getUnitBySuper = function getUnitBySuper(params) {
  return request({
    url: '/api/micro-jumbo-resource/unit/getUnitBySuper',
    method: 'get',
    params: _objectSpread({}, params)
  });
}; // 获取楼层维度菜单

export var getFloorBySuper = function getFloorBySuper(params) {
  return request({
    url: '/api/micro-jumbo-resource/floor/getFloorBySuper',
    method: 'get',
    params: _objectSpread({}, params)
  });
}; // 获取房间维度菜单

export var getHouseBySuper = function getHouseBySuper(params) {
  return request({
    url: '/api/micro-jumbo-resource/house/getHouseBySuper',
    method: 'get',
    params: _objectSpread({}, params)
  });
}; // 根据字典码获取字典值和名称

export var getDictKeyValueByCodes = function getDictKeyValueByCodes(params) {
  return request({
    url: '/api/micro-jumbo-resource/dict/getDictKeyValueByCodes',
    method: 'get',
    params: _objectSpread({}, params)
  });
}; // 获取分页数据

export function initData(url, params) {
  return request({
    url: '/api/micro-jumbo-resource/' + url,
    method: 'get',
    params: _objectSpread({}, params)
  });
} // 查看日志

export var getOperationLog = function getOperationLog(params) {
  return request({
    url: ' /api/micro-jumbo-resource/OperationLog',
    method: 'get',
    params: _objectSpread({}, params)
  });
}; // 架构树模糊搜索

export var treeSearchFuzzy = function treeSearchFuzzy(params) {
  return request({
    url: ' /api/micro-jumbo-resource/tree/treeSearchFuzzy',
    method: 'get',
    params: _objectSpread({}, params)
  });
};