export default [{
  path: '/billPrint',
  name: '查看发票',
  hidden: true,
  component: function component() {
    return import('@/views/billingSystem/billPrint');
  }
}, {
  path: '/receiptPrint',
  name: '收款收据',
  hidden: true,
  component: function component() {
    return import('@/views/billingSystem/receiptPrint');
  }
}, {
  path: '/handoverPrint',
  name: '交接单打印',
  hidden: true,
  component: function component() {
    return import('@/views/billingSystem/receiptPrint/handover');
  }
}];