import { setStore, getStore, removeStore } from '@/util/store';
import website from '@/config/website';
var common = {
  state: {
    language: getStore({
      name: 'language'
    }) || 'zh',
    isCollapse: false,
    isFullScren: false,
    isShade: false,
    screen: -1,
    isLock: getStore({
      name: 'isLock'
    }) || false,
    showTag: true,
    showDebug: true,
    showCollapse: true,
    showSearch: true,
    showLock: true,
    showFullScren: true,
    showTheme: true,
    showMenu: false,
    showColor: true,
    colorName: getStore({
      name: 'colorName'
    }) || '#409EFF',
    themeName: getStore({
      name: 'themeName'
    }) || 'theme-default',
    lockPasswd: getStore({
      name: 'lockPasswd'
    }) || '',
    website: website,
    // 通用收款单弹窗
    showBillDialog: false,
    // 缓存
    keepAlivePage: ['HouseSearch', 'resourceManage', 'reportForm']
  },
  mutations: {
    SET_LANGUAGE: function SET_LANGUAGE(state, language) {
      state.language = language;
      setStore({
        name: 'language',
        content: state.language
      });
    },
    SET_SHADE: function SET_SHADE(state, active) {
      state.isShade = active;
    },
    SET_COLLAPSE: function SET_COLLAPSE(state, value) {
      if (value !== undefined) {
        state.isCollapse = value;
      } else {
        state.isCollapse = !state.isCollapse;
      }
    },
    SET_FULLSCREN: function SET_FULLSCREN(state) {
      state.isFullScren = !state.isFullScren;
    },
    SET_LOCK: function SET_LOCK(state) {
      state.isLock = true;
      setStore({
        name: 'isLock',
        content: state.isLock,
        type: 'session'
      });
    },
    SET_SCREEN: function SET_SCREEN(state, screen) {
      state.screen = screen;
    },
    SET_COLOR_NAME: function SET_COLOR_NAME(state, colorName) {
      state.colorName = colorName;
      setStore({
        name: 'colorName',
        content: state.colorName
      });
    },
    SET_THEME_NAME: function SET_THEME_NAME(state, themeName) {
      state.themeName = themeName;
      setStore({
        name: 'themeName',
        content: state.themeName
      });
    },
    SET_LOCK_PASSWD: function SET_LOCK_PASSWD(state, lockPasswd) {
      state.lockPasswd = lockPasswd;
      setStore({
        name: 'lockPasswd',
        content: state.lockPasswd,
        type: 'session'
      });
    },
    CLEAR_LOCK: function CLEAR_LOCK(state) {
      state.isLock = false;
      state.lockPasswd = '';
      removeStore({
        name: 'lockPasswd',
        type: 'session'
      });
      removeStore({
        name: 'isLock',
        type: 'session'
      });
    },
    SHOW_DIALOG: function SHOW_DIALOG(state, payload) {
      state[payload.showDialog] = payload.flag;
    }
  }
};
export default common;