var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ky-top" },
    [
      _c("div", { staticClass: "main" }, [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c("img", {
              staticClass: "logo",
              attrs: { src: "img/bg/img-logo.png" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/wel")
                }
              }
            }),
            _vm._v(" "),
            _c("kyMenu")
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "user" },
          [
            _c("checkProject", {
              attrs: { treeData: _vm.treeData },
              on: { loadOAmessage: _vm.loadOAmessage }
            }),
            _vm._v(" "),
            _c(
              "el-badge",
              { staticClass: "item", attrs: { "is-dot": _vm.isDot } },
              [
                _c(
                  "span",
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "light",
                          content: "消息中心：" + _vm.totalMessage + "条",
                          placement: "bottom"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            icon: "el-icon-bell",
                            size: "mini",
                            circle: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.linkmessageSystem()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "top-bar__img",
              attrs: { src: _vm.userInfo.avatar }
            }),
            _vm._v(" "),
            _c(
              "el-dropdown",
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(
                    "\n                  " +
                      _vm._s(_vm.userInfo.userName) +
                      "\n                  "
                  ),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.editpsw($event)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "index-top-dropdown-item" }, [
                          _c("i", { staticClass: "el-icon-edit" }),
                          _vm._v(
                            "\n                      修改密码\n                    "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.logout($event)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "index-top-dropdown-item" }, [
                          _c("i", { staticClass: "el-icon-switch-button" }),
                          _vm._v(
                            "\n                      退出登录\n                    "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "kyu-dialog",
          attrs: {
            title: "修改密码",
            visible: _vm.changePswVisible,
            width: "30%",
            "before-close": _vm.handleClose,
            "append-to-body": "",
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.changePswVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "pwsForm",
              attrs: {
                model: _vm.passwordForm,
                disabled: _vm.loading,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { "show-password": "" },
                    model: {
                      value: _vm.passwordForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.passwordForm, "password", $$v)
                      },
                      expression: "passwordForm.password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "confirmPassword" } },
                [
                  _c("el-input", {
                    attrs: { "show-password": "" },
                    model: {
                      value: _vm.passwordForm.confirmPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.passwordForm, "confirmPassword", $$v)
                      },
                      expression: "passwordForm.confirmPassword"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-check",
                    loading: _vm.loading
                  },
                  on: { click: _vm.changePswSubmit }
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.handleClose }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("kyTags")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }