//
//
//
//
//
//
import CryptoJS from 'crypto-js';
export default {
  name: "app",
  data: function data() {
    return {};
  },
  watch: {},
  created: function created() {// this.$notify.success({
    //   title: "授权信息",
    //   dangerouslyUseHTMLString: true,
    //   message:
    //     '<p>该系统采用microX与AvueX开发，</p>' +
    //     '<p>若要商用强烈推荐高度定制的商业化框架，具体授权信息请访问如下地址。️</p>' +
    //     '</br>' +
    //     '<p>microX 授权地址：<a target="_blank" href="https:///#/vip">点击授权</a></p>' +
    //     '<p>AvueX 授权地址：<a target="_blank" href="https://avuex.avue.top/#/vip">点击授权</a></p>',
    //   duration: 20000,
    //   type: "success"
    // });
    // setTimeout(()=>{
    //   this.$notify.success({
    //     title: "开发手册",
    //     dangerouslyUseHTMLString: true,
    //     message:
    //       '<p>后端开发手册：<a target="_blank" href="https://www.kancloud.cn/smallchill/micro">点击查看</a></p>'+
    //       '<p>前端开发手册：<a target="_blank" href="https://www.kancloud.cn/smallwei/avue">点击查看</a></p>',
    //     duration: 20000,
    //     type: "success"
    //   });
    // },500);
  },
  mounted: function mounted() {
    if (this.$store.state.user.token && this.$store.state.user.token !== '') {
      this.loadRoutes(); // this.$store.dispatch('initMenu')
    }
  },
  methods: {
    loadRoutes: function loadRoutes() {
      this.$store.dispatch('GET_ROUTES');
      this.$store.dispatch('GET_BTNRULES');
    }
  },
  computed: {}
};