import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import kyTags from "./TagsView";
import top from "./top/";
import sidebar from "./sidebar/index";
import admin from "@/util/admin";
import kyMenu from "./top/ky-menu";
import systemVersion from "@/components/systemVersion"; //底部版本信息组件

export default {
  components: {
    top: top,
    sidebar: sidebar,
    kyTags: kyTags,
    kyMenu: kyMenu,
    systemVersion: systemVersion
  },
  name: "index",
  data: function data() {
    return {
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: "",
      processInfo: {}
    };
  },
  created: function created() {//实时检测刷新token
    // this.refreshToken();
  },
  mounted: function mounted() {
    this.processInfo = process.env.NODE_ENV; // this.init();
  },
  computed: _objectSpread({}, mapGetters(["isLock", "isCollapse", "website", "keepAlivePage"]), {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    }
  }),
  props: [],
  methods: {
    showCollapse: function showCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    // 屏幕检测
    init: function init() {
      var _this = this;

      this.$store.commit("SET_SCREEN", admin.getScreen());

      window.onresize = function () {
        setTimeout(function () {
          _this.$store.commit("SET_SCREEN", admin.getScreen());
        }, 0);
      };
    }
  }
};