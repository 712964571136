var getters = {
  tag: function tag(state) {
    return state.tags.tag;
  },
  language: function language(state) {
    return state.common.language;
  },
  website: function website(state) {
    return state.common.website;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  btnRules: function btnRules(state) {
    return state.user.btnRules;
  },
  colorName: function colorName(state) {
    return state.common.colorName;
  },
  themeName: function themeName(state) {
    return state.common.themeName;
  },
  isShade: function isShade(state) {
    return state.common.isShade;
  },
  isCollapse: function isCollapse(state) {
    return state.common.isCollapse;
  },
  keyCollapse: function keyCollapse(state, getters) {
    return getters.screen > 1 ? getters.isCollapse : false;
  },
  screen: function screen(state) {
    return state.common.screen;
  },
  isLock: function isLock(state) {
    return state.common.isLock;
  },
  isFullScren: function isFullScren(state) {
    return state.common.isFullScren;
  },
  lockPasswd: function lockPasswd(state) {
    return state.common.lockPasswd;
  },
  tagList: function tagList(state) {
    return state.tags.tagList;
  },
  tagWel: function tagWel(state) {
    return state.tags.tagWel;
  },
  token: function token(state) {
    return state.user.token;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission: function permission(state) {
    return state.user.permission;
  },
  menu: function menu(state) {
    return state.user.menu;
  },
  menuAll: function menuAll(state) {
    return state.user.menuAll;
  },
  logsList: function logsList(state) {
    return state.logs.logsList;
  },
  logsLen: function logsLen(state) {
    return state.logs.logsList.length || 0;
  },
  logsFlag: function logsFlag(state, getters) {
    return getters.logsLen === 0;
  },
  treeDetail: function treeDetail(state) {
    return state.treeMenu;
  },
  keepAlivePage: function keepAlivePage(state) {
    return state.common.keepAlivePage;
  },
  activeProject: function activeProject(state) {
    return state.user.activeProject;
  }
};
export default getters;