var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { "min-height": "100%" } },
    [
      _c(
        "el-header",
        { staticClass: "el-header", attrs: { height: "94px" } },
        [_c("top")],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        { staticClass: "el-main" },
        [
          _vm.processInfo !== "production"
            ? _c("el-alert", {
                staticClass: "env",
                attrs: {
                  title:
                    "您当前正在使用" +
                    (_vm.processInfo === "development"
                      ? "开发"
                      : _vm.processInfo === "test"
                      ? "测试"
                      : "正式") +
                    "环境!",
                  type: "warning",
                  "show-icon": "",
                  closable: false
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "keep-alive",
            { attrs: { include: _vm.keepAlivePage.join(",") } },
            [_c("router-view", { staticClass: "ky-view" })],
            1
          ),
          _vm._v(" "),
          _c("system-version")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }