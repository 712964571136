import Layout from '@/page/index/';
export default [{
  path: '/login',
  name: '登录页',
  component: function component() {
    return import(
    /* webpackChunkName: "page" */
    '@/page/login/index');
  },
  hidden: true,
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
}, {
  path: '/preview',
  component: function component() {
    return import('@/views/billingSystem/previewInvoice');
  },
  name: '发票预览',
  hidden: true
}, {
  path: '/lock',
  name: '锁屏页',
  component: function component() {
    return import(
    /* webpackChunkName: "page" */
    '@/page/lock/index');
  },
  hidden: true,
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
}, {
  path: '/404',
  component: function component() {
    return import(
    /* webpackChunkName: "page" */
    '@/components/error-page/404');
  },
  name: '404',
  hidden: true,
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
}, {
  path: '/403',
  component: function component() {
    return import(
    /* webpackChunkName: "page" */
    '@/components/error-page/403');
  },
  name: '403',
  hidden: true,
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
}, {
  path: '/500',
  component: function component() {
    return import(
    /* webpackChunkName: "page" */
    '@/components/error-page/500');
  },
  name: '500',
  hidden: true,
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
}, {
  path: '/wel',
  name: '首页',
  hidden: true,
  redirect: '/wel/index',
  component: function component() {
    return import('@/page/index');
  },
  children: [{
    path: 'index',
    hidden: true,
    component: function component() {
      return import(
      /* webpackChunkName: "page" */
      '@/views/wel');
    }
  }]
}, {
  path: '/test',
  name: '测试',
  hidden: true,
  component: function component() {
    return import('@/page/index');
  },
  redirect: '/test/index',
  children: [{
    path: 'index',
    hidden: true,
    component: function component() {
      return import(
      /* webpackChunkName: "page" */
      '@/views/test');
    }
  }]
}, {
  path: '/',
  name: '主页',
  redirect: '/wel',
  hidden: true
} // {
//     path: '/myiframe',
//     component: Layout,
//     redirect: '/myiframe',
//     hidden: true,
//     children: [{
//         path: ":routerPath",
//         name: 'iframe',
//         component: () =>
//             import( /* webpackChunkName: "page" */ '@/components/iframe/main'),
//         props: true
//     }]
// },
// {
//     hidden: true,
//     path: '*',
//     redirect: '/404'
// }
];