var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkproject-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "title",
          on: {
            click: function($event) {
              _vm.dialogVisible = true
            }
          }
        },
        [
          _c("i", { staticClass: "jhicon icon-address" }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "[" +
                _vm._s(
                  _vm.activeProject.name
                    ? _vm.activeProject.name +
                        "，当前账期：" +
                        _vm.currentYear +
                        " 年 " +
                        _vm.currentMonth +
                        " 月"
                    : "请选择项目"
                ) +
                "]"
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "kyu-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            width: "500px",
            "append-to-body": "",
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _vm._v("\n            切换项目\n            "),
              _c(
                "span",
                {
                  staticStyle: { "font-size": "13px", "margin-left": "230px" }
                },
                [_vm._v("项目编码：")]
              ),
              _vm._v(" "),
              _c("el-switch", {
                attrs: {
                  "active-color": "#409EFF",
                  "inactive-color": "#909399"
                },
                model: {
                  value: _vm.showCommunityCode,
                  callback: function($$v) {
                    _vm.showCommunityCode = $$v
                  },
                  expression: "showCommunityCode"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { margin: "15px 0" },
            attrs: { placeholder: "输入关键字进行过滤" },
            model: {
              value: _vm.filterText,
              callback: function($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText"
            }
          }),
          _vm._v(" "),
          _c("el-tree", {
            ref: "tree",
            staticClass: "checkproject-tree",
            attrs: {
              data: _vm.menuData,
              props: _vm.defaultProps,
              "filter-node-method": _vm.filterNode
            },
            on: { "node-click": _vm.handleClick },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c("span", { staticClass: "custom-tree-node" }, [
                    _c("span", [_vm._v(_vm._s(node.label))]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showCommunityCode,
                            expression: "showCommunityCode"
                          }
                        ],
                        staticStyle: { "margin-left": "25px" }
                      },
                      [_vm._v(_vm._s(data.code))]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }