var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avue-logo" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.keyCollapse
          ? _c("span", { key: "0", staticClass: "avue-logo_subtitle" }, [
              _c("img", { attrs: { src: "img/bg/img-logo.png", width: "30" } })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition-group",
        { attrs: { name: "fade" } },
        [
          !_vm.keyCollapse
            ? [
                _c("span", { key: "1", staticClass: "avue-logo_title" }, [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: "img/bg/img-logo.png",
                        width: "30",
                        alt: ""
                      }
                    }),
                    _vm._v(_vm._s(_vm.website.indexTitle))
                  ])
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }