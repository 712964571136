export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isCollapse: {
      type: Boolean,
      required: true
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title,
        isCollapse = _context$props.isCollapse;
    var vnodes = [];

    if (icon) {
      vnodes.push(h("i", {
        "class": icon
      }));
    }

    if (title && !isCollapse) {
      vnodes.push(h("span", {
        "slot": 'title',
        "style": "margin-left: 5px;"
      }, [title]));
    }

    return vnodes;
  }
};