import "core-js/modules/es6.number.constructor";
import _typeof from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import "core-js/modules/es6.function.name";
import { validatenull } from '@/util/validate';
import website from '@/config/website';
var keyName = website.key + '-';
/**
 * 存储localStorage
 */

export var setStore = function setStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name,
      content = params.content,
      type = params.type;
  name = keyName + name;
  var obj = {
    dataType: _typeof(content),
    content: content,
    type: type,
    datetime: new Date().getTime()
  };
  if (type) window.sessionStorage.setItem(name, JSON.stringify(obj));else window.localStorage.setItem(name, JSON.stringify(obj));
};
/**
 * 获取localStorage
 */

export var getStore = function getStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name,
      debug = params.debug;
  name = keyName + name;
  var obj = {},
      content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;

  try {
    obj = JSON.parse(obj);
  } catch (_unused) {
    return obj;
  }

  if (debug) {
    return obj;
  }

  if (obj.dataType == 'string') {
    content = obj.content;
  } else if (obj.dataType == 'number') {
    content = Number(obj.content);
  } else if (obj.dataType == 'boolean') {
    content = eval(obj.content);
  } else if (obj.dataType == 'object') {
    content = obj.content;
  }

  return content;
};
/**
 * 删除localStorage
 */

export var removeStore = function removeStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name,
      type = params.type;
  name = keyName + name;

  if (type) {
    window.sessionStorage.removeItem(name);
  } else {
    window.localStorage.removeItem(name);
  }
};
/**
 * 获取全部localStorage
 */

export var getAllStore = function getAllStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var list = [];
  var type = params.type;

  if (type) {
    for (var i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore({
          name: window.sessionStorage.key(i),
          type: 'session'
        })
      });
    }
  } else {
    for (var _i = 0; _i <= window.localStorage.length; _i++) {
      list.push({
        name: window.localStorage.key(_i),
        content: getStore({
          name: window.localStorage.key(_i)
        })
      });
    }
  }

  return list;
};
/**
 * 清空全部localStorage
 */

export var clearStore = function clearStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = params.type;

  if (type) {
    window.sessionStorage.clear();
  } else {
    window.localStorage.clear();
  }
};