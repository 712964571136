/**
 * 全站权限配置
 *
 */
import router from "./router/router";
import store from "./store"; // import { validatenull } from '@/util/validate'

import { getToken } from '@/util/auth';
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css'; // progress bar style
// import { TabPane } from 'element-ui'

NProgress.configure({
  showSpinner: false
}); // const lockPage = store.getters.website.lockPage; //锁屏页

var whiteList = ['/login'];
router.beforeEach(function (to, from, next) {
  NProgress.start(); // //缓冲设置
  // if (to.meta.keepAlive === true && store.state.tags.tagList.some(ele => {
  //     return ele.value === to.fullPath;
  // })) {
  //     to.meta.$keepAlive = true;
  // } else {
  //     NProgress.start()
  //     if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
  //         to.meta.$keepAlive = true;
  //     } else {
  //         to.meta.$keepAlive = false;
  //     }
  // }
  // const meta = to.meta || {};
  // if (getToken()) {
  //     console.log(to.path)
  //     if (to.path === '/login') { //如果登录成功访问登录页跳转到主页
  //         // next({ path: '/billingSystem' })
  //         console.log(1231231231223)
  //         // if(store.getters.token.length) {
  //         //     console.log(store.getters.token.length)
  //         //     // next({ path: '/billingSystem' })
  //         //     next({ path: '/billingSystem' })
  //         // }
  //         next()
  //         // next()
  //         // next(false)
  //     } else {
  //         //如果用户信息为空则获取用户信息，获取用户信息失败，跳转到登录页
  //         if (store.getters.token.length === 0) {
  //             store.dispatch('FedLogOut').then(() => {
  //                 next({ path: '/login' })
  //             })
  //         } else {
  //             next()
  //         }
  //     }
  // }

  if (getToken() && store.getters.token.length) {
    if (to.path === '/login') {
      next({
        path: '/wel/index'
      });
    } else {
      next();
    }

    NProgress.done();
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({
        path: '/login'
      }); // 否则全部重定向到登录页
    }

    NProgress.done();
  }
});
router.afterEach(function () {
  NProgress.done(); // let title = store.getters.tag.label;
  // let i18n = store.getters.tag.meta.i18n;
  // title = router.$avueRouter.generateTitle(title, i18n)
  // //根据当前的标签也获取label的值动态设置浏览器标题
  // router.$avueRouter.setTitle(title);
});