// 配置编译环境和线上环境之间的切换
var baseUrl = '';
var chargeSystemUrl = '';
var systemUrl = '';
var iconfontVersion = ['567566_pwc3oottzol', '1066523_v8rsbcusj5q'];
var iconfontUrl = "//at.alicdn.com/t/font_$key.css";
var junhaoUrl = 'http://testcharging.xiaofukeji.com:7006';
var codeUrl = "".concat(baseUrl, "/code");
var env = process.env;

if (env.NODE_ENV == 'development') {
  baseUrl = "http://120.77.171.20:8132"; // 开发环境地址

  chargeSystemUrl = 'http://120.77.171.20:8130';
} else if (env.NODE_ENV == 'production') {
  baseUrl = "http://120.77.171.20:8132"; //生产环境地址

  chargeSystemUrl = 'http://120.77.171.20:8130';
} else if (env.NODE_ENV == 'test') {
  baseUrl = "https://wxtest.itwaterview.com.cn/api/micro-jumbo-resource"; //测试环境地址

  chargeSystemUrl = 'https://wxtest.itwaterview.com.cn/api/micro-jumbo-charging';
  systemUrl = "https://wxtest.itwaterview.com.cn";
}

export { baseUrl, iconfontUrl, iconfontVersion, codeUrl, junhaoUrl, chargeSystemUrl, systemUrl, env };