import _typeof from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import { validatenull } from "./validate";
import { getToken } from '@/util/auth';
import website from '@/config/website';
import { Base64 } from 'js-base64';
import CryptoJS from 'crypto-js';
import * as math from 'mathjs';
export var Authorization = "Basic ".concat(Base64.encode("".concat(website.clientId, ":").concat(website.clientSecret)));
export var microAuth = 'bearer ' + getToken(); //表单序列化

export var serialize = function serialize(data) {
  var list = [];
  Object.keys(data).forEach(function (ele) {
    list.push("".concat(ele, "=").concat(encodeURI(data[ele])));
  });
  return list.join('&');
};
export var getObjType = function getObjType(obj) {
  var toString = Object.prototype.toString;
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  };

  if (obj instanceof Element) {
    return 'element';
  }

  return map[toString.call(obj)];
};
/**
 * 对象深拷贝
 */

export var deepClone = function deepClone(data) {
  var type = getObjType(data);
  var obj;

  if (type === 'array') {
    obj = [];
  } else if (type === 'object') {
    obj = {};
  } else {
    //不再具有下一层次
    return data;
  }

  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }

  return obj;
};
/**
 * 设置灰度模式
 */

export var toggleGrayMode = function toggleGrayMode(status) {
  if (status) {
    document.body.className = document.body.className + ' grayMode';
  } else {
    document.body.className = document.body.className.replace(' grayMode', '');
  }
};
/**
 * 设置主题
 */

export var setTheme = function setTheme(name) {
  document.body.className = name;
};
/**
 * 加密处理
 */

export var encryption = function encryption(params) {
  var data = params.data,
      type = params.type,
      param = params.param,
      key = params.key;
  var result = JSON.parse(JSON.stringify(data));

  if (type == 'Base64') {
    param.forEach(function (ele) {
      result[ele] = btoa(result[ele]);
    });
  } else if (type == 'Aes') {
    param.forEach(function (ele) {
      result[ele] = window.CryptoJS.AES.encrypt(result[ele], key).toString();
    });
  }

  return result;
};
/**
 * 浏览器判断是否全屏
 */

export var fullscreenToggel = function fullscreenToggel() {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
};
/**
 * esc监听全屏
 */

export var listenfullscreen = function listenfullscreen(callback) {
  function listen() {
    callback();
  }

  document.addEventListener("fullscreenchange", function () {
    listen();
  });
  document.addEventListener("mozfullscreenchange", function () {
    listen();
  });
  document.addEventListener("webkitfullscreenchange", function () {
    listen();
  });
  document.addEventListener("msfullscreenchange", function () {
    listen();
  });
};
/**
 * 浏览器判断是否全屏
 */

export var fullscreenEnable = function fullscreenEnable() {
  var isFullscreen = document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;
  return isFullscreen;
};
/**
 * 浏览器全屏
 */

export var reqFullScreen = function reqFullScreen() {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  }
};
/**
 * 浏览器退出全屏
 */

export var exitFullScreen = function exitFullScreen() {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen();
  }
};
/**
 * 递归寻找子类的父类
 */

export var findParent = function findParent(menu, id) {
  for (var i = 0; i < menu.length; i++) {
    if (menu[i].children.length != 0) {
      for (var j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id == id) {
          return menu[i];
        } else {
          if (menu[i].children[j].children.length != 0) {
            return findParent(menu[i].children[j].children, id);
          }
        }
      }
    }
  }
};
/**
 * 判断2个对象属性和值是否相等
 */

/**
 * 动态插入css
 */

export var loadStyle = function loadStyle(url) {
  var link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};
/**
 * 判断路由是否相等
 */

export var diff = function diff(obj1, obj2) {
  delete obj1.close;
  var o1 = obj1 instanceof Object;
  var o2 = obj2 instanceof Object;

  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false; //Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object;
    var t2 = obj2[attr] instanceof Object;

    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr]);
    } else if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }

  return true;
};
/**
 * 根据字典的value显示label
 */

export var findByvalue = function findByvalue(dic, value) {
  var result = '';
  if (validatenull(dic)) return value;

  if (typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean') {
    var index = 0;
    index = findArray(dic, value);

    if (index != -1) {
      result = dic[index].label;
    } else {
      result = value;
    }
  } else if (value instanceof Array) {
    result = [];
    var _index = 0;
    value.forEach(function (ele) {
      _index = findArray(dic, ele);

      if (_index != -1) {
        result.push(dic[_index].label);
      } else {
        result.push(value);
      }
    });
    result = result.toString();
  }

  return result;
};
/**
 * 根据字典的value查找对应的index
 */

export var findArray = function findArray(dic, value) {
  for (var i = 0; i < dic.length; i++) {
    if (dic[i].value == value) {
      return i;
    }
  }

  return -1;
};
/**
 * 生成随机len位数字
 */

export var randomLenNum = function randomLenNum(len, date) {
  var random = '';
  random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len ? len : 4);
  if (date) random = random + Date.now();
  return random;
};
/**
 * 打开小窗口
 */

export var openWindow = function openWindow(url, title, w, h) {
  // Fixes dual-screen position                            Most browsers       Firefox
  var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;
  var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
  var left = width / 2 - w / 2 + dualScreenLeft;
  var top = height / 2 - h / 2 + dualScreenTop;
  var newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left); // Puts focus on the newWindow

  if (window.focus) {
    newWindow.focus();
  }
}; // 判断是否为空值

export var isEmpty = function isEmpty(v) {
  switch (_typeof(v)) {
    case 'undefined':
      return true;

    case 'string':
      if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true;
      break;

    case 'boolean':
      if (!v) return true;
      break;

    case 'number':
      if (0 === v || isNaN(v)) return true;
      break;

    case 'object':
      if (null === v || v.length === 0) return true;

      for (var i in v) {
        return false;
      }

      return true;
  }

  return false;
}; // 数字末尾补两位小数

export var toDecimal2 = function toDecimal2(x) {
  var result = parseFloat(x);

  if (isNaN(result)) {
    return x;
  }

  result = Math.round(x * 100) / 100;
  var s = result.toString();
  var rs = s.indexOf('.');

  if (rs < 0) {
    rs = s.length;
    s += '.';
  }

  while (s.length <= rs + 2) {
    s += '0';
  }

  return s;
};
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
} // 加密

export function encryptData(data, timeStamp) {
  var encryptKey = CryptoJS.enc.Utf8.parse(timeStamp + '000');
  var iv = CryptoJS.enc.Utf8.parse(timeStamp + '000');
  var encrypted = CryptoJS.AES.encrypt(data, encryptKey, {
    iv: iv,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
} // 解密

export function decryptData(data, timeStamp) {
  var decryptKey = CryptoJS.enc.Utf8.parse(timeStamp + '000');
  var decryptIv = CryptoJS.enc.Utf8.parse(timeStamp + '000');
  var decrypted = CryptoJS.AES.decrypt(data, decryptKey, {
    iv: decryptIv,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
} //四舍五入保留两位小数

export function fixTwo(num) {
  var res = Math.round(math.format(math.chain(math.bignumber(num)).multiply(math.bignumber(100)).done()));
  return math.format(math.chain(math.bignumber(res)).divide(math.bignumber(100)).done());
}