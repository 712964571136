var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avue-sidebar" },
    [
      _c("logo"),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        {
          staticStyle: { height: "100%" },
          attrs: { "wrap-class": "scrollbar-wrapper" }
        },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.active,
                collapse: _vm.isCollapse,
                "unique-opened": false,
                "collapse-transition": false,
                mode: "vertical",
                "background-color": "#20222A",
                "text-color": "#fff",
                "active-text-color": "#409eff"
              }
            },
            _vm._l(_vm.routes, function(route) {
              return _c("sidebar-item", {
                key: route.path,
                staticClass: "slider-item",
                attrs: { item: route, "base-path": route.path }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }