import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import request from '@/router/axios'; //查询可生成合同的楼（除别墅以外的楼宇）

export var queryCanGenerateAllHouses = function queryCanGenerateAllHouses(params) {
  return request({
    url: '/api/micro-jumbo-charging/queryCanGenerateAllHouses',
    method: 'GET',
    params: _objectSpread({}, params)
  });
}; //查询用户消息数量

export var queryUnreadInfo = function queryUnreadInfo(params) {
  return request({
    url: '/api/micro-jumbo-charging/queryUnreadInfo',
    method: 'GET',
    params: _objectSpread({}, params)
  });
}; //查询消息列表（个人：0/项目：1/系统：2）

export var queryMessageList = function queryMessageList(params) {
  return request({
    url: '/api/micro-jumbo-charging/queryMessageList',
    method: 'GET',
    params: _objectSpread({}, params)
  });
}; //查看消息详情

export var queryNewsInfo = function queryNewsInfo(params) {
  return request({
    url: '/api/micro-jumbo-charging/queryNewsInfo',
    method: 'GET',
    params: _objectSpread({}, params)
  });
}; //修改消息状态

export var updateMessageState = function updateMessageState(params) {
  return request({
    url: '/api/micro-jumbo-charging/updateMessageState',
    method: 'PUT',
    params: _objectSpread({}, params)
  });
}; // // 月底结账提醒功能
// export const monthEndCarryForwardReminder = (params) => {
//     return request({
//         url: '/api/micro-jumbo-charging/delivery/monthEndCarryForwardReminder',
//         method: 'POST',
//         data: params,
//         meta: {
//             isSerialize: true
//         }
//     })
// }