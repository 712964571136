//
//
//
//
//
//
//
//
export default {
  name: "basicContainer"
};