import "core-js/modules/es6.regexp.to-string";
import { setStore, getStore } from '@/util/store';
import { dateFormat } from '@/util/date';
import { sendLogs } from '@/api/user';
var logs = {
  state: {
    logsList: getStore({
      name: 'logsList'
    }) || []
  },
  actions: {
    //发送错误日志
    SendLogs: function SendLogs(_ref) {
      var state = _ref.state,
          commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        sendLogs(state.logsList).then(function () {
          commit('CLEAR_LOGS');
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  },
  mutations: {
    ADD_LOGS: function ADD_LOGS(state, _ref2) {
      var type = _ref2.type,
          message = _ref2.message,
          stack = _ref2.stack,
          info = _ref2.info;
      state.logsList.push(Object.assign({
        url: window.location.href,
        time: dateFormat(new Date())
      }, {
        type: type,
        message: message,
        stack: stack,
        info: info.toString()
      }));
      setStore({
        name: 'logsList',
        content: state.logsList
      });
    },
    CLEAR_LOGS: function CLEAR_LOGS(state) {
      state.logsList = [];
      setStore({
        name: 'logsList',
        content: state.logsList
      });
    }
  }
};
export default logs;