import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  computed: {
    topRoute: function topRoute() {
      var _this = this;

      if (!this.$store.state.user.routes.length) {
        return {};
      }

      var route = this.$store.state.user.routes.filter(function (item) {
        return item.code === 'SFXT';
      })[0];
      var routeList = route.children.concat();
      var formatList = {};

      if (['/messageSystem/index'].includes(this.$route.fullPath)) {
        formatList['消息中心'] = {
          path: '/messageSystem/index',
          // code: "ZYGLXT",
          meta: {
            title: '消息中心' // icon: 'jhicon icon-fangwufangchan'

          }
        };
      } else {
        routeList.forEach(function (item, index) {
          if (index === 1 && _this.$store.state.user.roles.includes('ZYGLXT')) {
            formatList['基础管理'] = {
              path: '/resourceManage/wrapper',
              code: "ZYGLXT",
              meta: {
                title: '资源管理',
                icon: 'jhicon icon-fangwufangchan'
              }
            };
          }

          item.path = item.path.includes(route.path) ? item.path : "".concat(route.path, "/").concat(item.path);

          if (item.meta && item.meta.topGroup) {
            if (formatList[item.meta.topGroup]) {
              formatList[item.meta.topGroup].children.push(item);
            } else {
              formatList[item.meta.topGroup] = {
                title: item.meta.topGroup,
                children: [item]
              };
            }
          } else {
            formatList[item.name] = item;
          }
        });
      }

      return formatList;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    }
  },
  methods: {
    hasChildren: function hasChildren(children) {
      return children.every(function (item) {
        return item.meta && item.meta.topMenuHidden;
      });
    }
  }
};