import _toConsumableArray from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import Layout from '@/page/index/';
import reportForm from "./reportForm";
export default {
  path: '/billingSystem',
  code: 'SFXT',
  redirect: '/billingSystem/houseSearch',
  component: Layout,
  meta: {
    title: '收费系统',
    icon: 'jhicon icon-shoufei'
  },
  children: [{
    path: 'houseSearch',
    name: '前台收费',
    code: "SFXT-01-0000",
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/houseSearch');
    }
  }, {
    path: 'headquartersConfig',
    name: '总部配置',
    code: "SFXT-11-0000",
    hidden: true,
    meta: {
      topGroup: '收费管理'
    },
    component: function component() {
      return import('@/views/billingSystem/headquartersConfig');
    }
  }, {
    path: 'projectConfig',
    name: '收费配置',
    code: "SFXT-12-0000",
    hidden: true,
    meta: {
      topGroup: '收费管理'
    },
    component: function component() {
      return import('@/views/billingSystem/projectConfig/index');
    }
  }, {
    path: 'roomCharging/index',
    name: '生成常规费用',
    code: 'SFXT-27-0000',
    hidden: true,
    meta: {
      topGroup: '收费管理'
    },
    component: function component() {
      return import('@/views/billingSystem/roomCharging/index');
    }
  }, {
    path: 'meterReading/index',
    name: '生成仪表费用',
    code: "SFXT-15-0000",
    hidden: true,
    meta: {
      topGroup: '收费管理'
    },
    component: function component() {
      return import('@/views/billingSystem/meterReading/index');
    }
  }, {
    path: 'meterReading/detail',
    name: '抄表详情',
    code: "SFXT-15-0000",
    hidden: true,
    meta: {
      topGroup: '收费管理',
      topMenuHidden: true
    },
    component: function component() {
      return import('@/views/billingSystem/meterReading/detail');
    }
  }, {
    path: 'roomCharging/create',
    name: '生成批量应收',
    code: 'SFXT-27-0000',
    hidden: true,
    meta: {
      topGroup: '收费管理',
      topMenuHidden: true
    },
    component: function component() {
      return import('@/views/billingSystem/roomCharging/create');
    }
  }, {
    path: 'handoverList',
    name: '交接单',
    code: "SFXT-19-0000",
    hidden: true,
    meta: {
      topGroup: '收费管理'
    },
    component: function component() {
      return import('@/views/billingSystem/handoverList');
    }
  }, // 暂时隐藏报表及财务相关（缴费通知单，财务一体化）
  // {
  // path: 'paymentNotice',
  // name: '缴费通知单',
  // hidden: true,
  // meta: {
  // 	topGroup: '财务相关'
  // },
  // // component: () => import('@/views/billingSystem/handoverList'),
  // },  
  // {
  // 	path: 'financialIntegration',
  // 	name: '财务一体化',
  // 	hidden: true,
  // 	meta: {
  // 		topGroup: '财务相关'
  // 	},
  // 	// component: () => import('@/views/billingSystem/handoverList'),
  // },
  {
    path: 'automaticConfig',
    name: '自动结转配置',
    hidden: true,
    code: "SFXT-30-0000",
    component: function component() {
      return import('@/views/billingSystem/projectConfig/components/automaticConfig');
    },
    meta: {
      topGroup: '财务管理'
    }
  }, {
    path: 'bankAccountConfig',
    name: '银行帐号配置',
    hidden: true,
    code: "SFXT-31-0000",
    component: function component() {
      return import('@/views/billingSystem/projectConfig/components/bankAccountConfig');
    },
    meta: {
      topGroup: '财务管理'
    }
  }, {
    path: 'invoiceManage',
    name: '发票管理',
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/invoiceManage');
    },
    meta: {
      topGroup: '财务管理'
    }
  }, {
    path: 'contractCharging',
    name: '收款合同',
    code: "SFXT-16-0000",
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/contractCharging');
    },
    meta: {
      topGroup: '财务管理'
    }
  }, {
    path: 'merchantConfig',
    name: '商户配置',
    code: "SFXT-35-0000",
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/projectConfig/components/merchantConfig');
    },
    meta: {
      topGroup: '财务管理'
    }
  }, {
    path: 'payRecord',
    name: '缴费记录',
    code: "SFXT-36-0000",
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/projectConfig/components/payRecord');
    },
    meta: {
      topGroup: '财务管理'
    }
  }, {
    path: 'invoiceApplication',
    name: '开票申请',
    code: "SFXT-37-0000",
    hidden: true,
    component: function component() {
      return import('@/views/billingSystem/projectConfig/components/invoiceApplication');
    },
    meta: {
      topGroup: '财务管理'
    }
  }].concat(_toConsumableArray(reportForm))
};