import _toConsumableArray from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray";
import _slicedToArray from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
export default {
  namespaced: true,
  state: {
    visitedViews: []
  },
  mutations: {
    ADD_VISITED_VIEW: function ADD_VISITED_VIEW(state, view) {
      //修复：如果新增TAG时发现已经存在TAG则替换query参数
      if (state.visitedViews.some(function (v) {
        return v.path === view.path;
      })) {
        state.visitedViews.map(function (v) {
          if (v.path === view.path) {
            v.query = view.query;
          }
        });
        return;
      }

      state.visitedViews.push(Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      }));
    },
    DEL_VISITED_VIEW: function DEL_VISITED_VIEW(state, view) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = state.visitedViews.entries()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _step$value = _slicedToArray(_step.value, 2),
              i = _step$value[0],
              v = _step$value[1];

          if (v.path === view.path) {
            state.visitedViews.splice(i, 1);
            break;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    DEL_ALL_VISITED_VIEWS: function DEL_ALL_VISITED_VIEWS(state) {
      // keep affix tags
      var affixTags = state.visitedViews.filter(function (tag) {
        return tag.meta.affix;
      });
      state.visitedViews = affixTags;
    }
  },
  actions: {
    addView: function addView(_ref, view) {
      var commit = _ref.commit;
      commit('ADD_VISITED_VIEW', view);
    },
    delView: function delView(_ref2, view) {
      var dispatch = _ref2.dispatch,
          state = _ref2.state;
      return new Promise(function (resolve) {
        dispatch('delVisitedView', view);
        resolve({
          visitedViews: _toConsumableArray(state.visitedViews)
        });
      });
    },
    delVisitedView: function delVisitedView(_ref3, view) {
      var commit = _ref3.commit,
          state = _ref3.state;
      return new Promise(function (resolve) {
        commit('DEL_VISITED_VIEW', view);
        resolve(_toConsumableArray(state.visitedViews));
      });
    },
    delAllViews: function delAllViews(_ref4, view) {
      var dispatch = _ref4.dispatch,
          state = _ref4.state;
      return new Promise(function (resolve) {
        dispatch('delAllVisitedViews', view);
        resolve({
          visitedViews: _toConsumableArray(state.visitedViews)
        });
      });
    },
    delAllVisitedViews: function delAllVisitedViews(_ref5) {
      var commit = _ref5.commit,
          state = _ref5.state;
      return new Promise(function (resolve) {
        commit('DEL_ALL_VISITED_VIEWS');
        resolve(_toConsumableArray(state.visitedViews));
      });
    }
  }
};