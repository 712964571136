import { getAccountDataPermissions, getBuildingBySuper, getUnitBySuper, getFloorBySuper, getHouseBySuper, treeSearchFuzzy } from "@/api/resourceManage/common"; //使用资源系统树接口

export default {
  state: {
    menuData: [],
    resourceSearchData: []
  },
  mutations: {
    SET_MENUDATA: function SET_MENUDATA(state, menu) {
      // const obj = {}
      // state.menuData.forEach(item => {
      //     obj[item.org_uuid] = item
      // })
      // menu.forEach(item => {
      //     obj[item.org_uuid] = item
      // })
      // state.menuData = Object.keys(obj).map(item => {
      //     return obj[item]
      // })
      // state.resourceSearchData = Object.keys(obj).map(item => {
      //     return obj[item]
      // })
      state.menuData = menu;
      state.resourceSearchData = menu;
    },
    CLEAR_MENUDATA: function CLEAR_MENUDATA(state, menu) {
      state.menuData = menu;
    },
    SEARCH_MENUDATA: function SEARCH_MENUDATA(state, _ref) {
      var menu = _ref.menu,
          type = _ref.type;

      if (type === 'resourceManage') {
        state.resourceSearchData = menu;
      } else {
        state.menuData = menu;
      }
    }
  },
  actions: {
    // 获取菜单初始
    initMenu: function initMenu(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        getAccountDataPermissions().then(function (res) {
          commit('SET_MENUDATA', res.data.content);
          resolve();
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    changeActive: function changeActive(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        getAccountDataPermissions().then(function (res) {
          commit('CLEAR_MENUDATA', res.data.content);
          resolve();
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    searchTree: function searchTree(_ref4, _ref5) {
      var commit = _ref4.commit;
      var keyword = _ref5.keyword,
          searchType = _ref5.searchType,
          type = _ref5.type,
          communityUuid = _ref5.communityUuid,
          _ref5$houseUuid = _ref5.houseUuid,
          houseUuid = _ref5$houseUuid === void 0 ? '' : _ref5$houseUuid;
      return new Promise(function (resolve, reject) {
        var params = {
          keyword: keyword,
          searchType: searchType,
          houseUuid: houseUuid
        };

        if (communityUuid) {
          params.communityUuid = communityUuid;
        }

        treeSearchFuzzy(params).then(function (res) {
          commit('SEARCH_MENUDATA', {
            menu: res.data.content,
            type: type
          });
          resolve(res.data.content);
        });
      });
    },
    lazyLoadData: function lazyLoadData(_ref6, _ref7) {
      var state = _ref6.state;
      var type = _ref7.type,
          orgUuid = _ref7.orgUuid,
          searchProject = _ref7.searchProject;
      return new Promise(function (resolve, reject) {
        switch (type) {
          case "1":
            if (searchProject) {
              return resolve(state.resourceSearchData.filter(function (item) {
                return item.parent_id === orgUuid;
              }) || []);
            } else {
              return resolve(state.menuData.filter(function (item) {
                return item.parent_id === orgUuid;
              }) || []);
            }

          case '2':
            getBuildingBySuper({
              communityUuids: orgUuid
            }).then(function (res) {
              // commit('SET_MENUDATA', res.data.content)
              resolve(res.data.content);
            }).catch(function (err) {
              reject(err);
            });
            break;

          case '3':
            getUnitBySuper({
              buildingUuids: orgUuid
            }).then(function (res) {
              var result = res.data.content;
              result.map(function (item) {
                if (item.type === "6") {
                  item.leaf = true;
                }
              }); // commit('SET_MENUDATA', res.data.content)

              resolve(result);
            });
            break;

          case '4':
            getFloorBySuper({
              unitUuids: orgUuid
            }).then(function (res) {
              var result = res.data.content;
              result.map(function (item) {
                if (item.type === "6") {
                  item.leaf = true;
                }
              }); // commit('SET_MENUDATA', res.data.content)

              resolve(result);
            });
            break;

          case '5':
            getHouseBySuper({
              floorUuids: orgUuid
            }).then(function (res) {
              var result = res.data.content;
              result.map(function (item) {
                if (item.type === "6") {
                  item.leaf = true;
                }
              }); // commit('SET_MENUDATA', res.data.content)

              resolve(result);
            });
            break;

          default:
            resolve([]);
            break;
        }
      });
    }
  }
};