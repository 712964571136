import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/www/html/junhao-front-charging/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";
import "core-js/modules/es6.function.name";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import store from '@/store';
import moment from 'moment';
import '@/util/dialogDrag';
import { getAccountDataPermissions } from "@/api/resourceManage/common";
export default {
  name: "checkProject",
  props: {
    treeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      //树节点搜索
      filterText: '',
      // 树形菜单数据
      menuData: [],
      defaultProps: {
        children: "children",
        label: function label(data) {
          return data.name;
        },
        isLeaf: "leaf"
      },
      dialogVisible: false,
      //是否展示项目编码
      showCommunityCode: false,
      //当前账期
      currentYear: moment().format('YYYY'),
      currentMonth: moment().format('MM')
    };
  },
  computed: _objectSpread({}, mapGetters(['activeProject'])),
  mounted: function mounted() {
    this.initMenu();
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    initMenu: function initMenu() {
      var _this = this;

      if (this.treeData.length > 0) {
        this.menuData = this.toTree(this.treeData);
      } else {
        getAccountDataPermissions().then(function (res) {
          var data = res.data;

          if (data.code === 0) {
            if (data.content.length) {
              _this.menuData = _this.toTree(data.content); // this.treeData = data.content
            }
          }
        });
      }
    },
    toTree: function toTree(data) {
      // 删除 所有 children,以防止多次调用
      data.forEach(function (item) {
        delete item.children;
      }); // 将数据存储为 以 org_uuid 为 KEY 的 map 索引数据列

      var map = {};
      data.forEach(function (item) {
        map[item.org_uuid] = item;
      });
      var val = [];
      data.forEach(function (item) {
        // 以当前遍历项，的pid,去map对象中找到索引的id
        var parent = map[item.parent_id]; // 如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中

        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          //如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
          val.push(item);
        }
      });
      return val;
    },
    handleClick: function handleClick(item) {
      if (item.type === '2') {
        this.$store.commit('SET_ACTIVE_PROJECT', item);
        this.$store.dispatch('changeActive');
        this.$router.push('/');
        this.$store.dispatch('tagsView/delAllViews'); //切换小区时 清空报表缓存内容

        var index = store.state.common.keepAlivePage.findIndex(function (item) {
          return item === 'reportForm';
        });
        store.state.common.keepAlivePage.splice(index, 1);
        this.$message({
          offset: 50,
          message: "\u5DF2\u4E3A\u60A8\u5207\u6362\u5728\u7BA1\u9879\u76EE\u81F3\u3010".concat(item.name, "\u3011\uFF0C\u8BF7\u8C28\u614E\u64CD\u4F5C\u4E1A\u52A1\uFF01"),
          type: 'warning'
        });
        this.dialogVisible = false; //切换项目后 重新获取消息中心数据

        this.$emit('loadOAmessage');
      }
    },
    //节点搜索筛选
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
  }
};