import Vue from 'vue';
import Vuex from 'vuex';
import user from "./modules/user";
import common from "./modules/common";
import logs from "./modules/logs";
import getters from "./getters";
import tagsView from "./modules/tagsView";
import treeMenu from "./modules/treeMenu";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    user: user,
    common: common,
    logs: logs,
    tagsView: tagsView,
    treeMenu: treeMenu
  },
  getters: getters
});
export default store;