import "core-js/modules/es6.function.name";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/www/html/junhao-front-charging/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es6.array.iterator.js";
import "/www/html/junhao-front-charging/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es6.promise.js";
import "/www/html/junhao-front-charging/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es6.object.assign.js";
import "/www/html/junhao-front-charging/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue'; // import axios from './router/axios';
// import VueAxios from 'vue-axios';

import App from "./App";
import "./permission"; // 权限

import "./error"; // 日志

import store from "./store";
import router from "./router/router"; // import { loadStyle } from './util/util'

import * as urls from '@/config/env';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // import {
//     iconfontUrl,
//     iconfontVersion
// } from '@/config/env';

import _i18n from "./lang"; // Internationalization

import "./styles/common.scss";
import basicContainer from "./components/basic-container/main";
import "./styles/iconfont/iconfont.scss"; // Vue.use(router)
// Vue.use(VueAxios, axios)

Vue.use(Element, {
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  },
  size: 'small'
}); // Vue.use(window.AVUE, {
//     i18n: (key, value) => i18n.t(key, value)
// })
//注册全局容器

Vue.component('basicContainer', basicContainer); // 加载相关url地址

Object.keys(urls).forEach(function (key) {
  Vue.prototype[key] = urls[key];
}); // 监听storage 同步多页面中的项目信息

window.addEventListener('storage', function (e) {
  if (e.key === 'micro-activeProject') {
    var newValue = JSON.parse(e.newValue);
    var oldValue = JSON.parse(e.oldValue); // 判断新旧项目是否相同，不同则进行更新操作

    if (newValue.content && newValue.content.org_uuid != oldValue.content.org_uuid && newValue.content.type === '2') {
      store.commit('SET_ACTIVE_PROJECT', newValue.content || {});
      router.push("/");
      store.dispatch("tagsView/delAllViews");
      Element.Message({
        message: "\u5DF2\u4E3A\u60A8\u5207\u6362\u5728\u7BA1\u9879\u76EE\u81F3\u3010".concat(newValue.content.name, "\u3011\uFF0C\u8BF7\u8C28\u614E\u64CD\u4F5C\u4E1A\u52A1\uFF01"),
        type: "warning"
      });
    }
  }
}); // 动态加载阿里云字体库
// iconfontVersion.forEach(ele => {
//     loadStyle(iconfontUrl.replace('$key', ele));
// })

Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');